import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmationConfig } from './confirmation-email.types';

@Component({
  selector: 'dialog-email',
  templateUrl: './dialog-email.component.html',
  styles: [
    `
        .fuse-confirmation-dialog-panel {
            @screen md {
                @apply w-128;
            }

            .mat-dialog-container {
                padding: 0 !important;
            }
        }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class DialogEmailComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationConfig) {
  }
}
