import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { FuseUtilsService } from '@fuse/services/utils';
import { AlertComponent } from './alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private durationInSeconds = 5;

  private alert = {
    appearance: 'outline',
    dismissed: false,
    dismissible: false,
    name: this._fuseUtilsService.randomId(),
    showIcon: true,
  };

  private config: MatSnackBarConfig = {
    duration: this.durationInSeconds * 1000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
    panelClass: ['p-0', 'rounded', 'bg-card'],
  };

  constructor(
    private _snackBar: MatSnackBar,
    private _fuseUtilsService: FuseUtilsService
  ) {}

  primary(message): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'primary',
      },
    });
  }

  basic(message): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'basic',
      },
    });
  }

  info(message): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'info',
      },
    });
  }

  success(message): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'success',
      },
    });
  }

  error(message: string): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'error',
      },
    });
  }

  warning(message: string): void {
    this._snackBar.openFromComponent(AlertComponent, {
      ...this.config,
      data: {
        ...this.alert,
        message: message,
        type: 'warning',
      },
    });
  }

  dismiss(): void {
    this._snackBar.dismiss();
  }
}
