import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CpfCnpjValidator } from "@fuse/directives/cpfcnpj-validator/cpfcnpj.validator";
import { ConfirmationServidorConfig } from "./confirmation-servidor.types";

@Component({
  selector: "app-dialog-servidor",
  templateUrl: "./dialog-servidor.component.html",
  styles: [
    `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-160;
        }

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `,
  ],
})
export class DialogServidorComponent implements OnInit {
  form: FormGroup;

  constructor(
    public _fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogServidorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationServidorConfig
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      cpf: ['', [Validators.required, CpfCnpjValidator.validate]],
      nome: ['', Validators.required],
    });
  }

  incluirServidor() {
    sessionStorage.setItem("usuario", JSON.stringify(this.form.value));
    this.dialogRef.close();
  }
}
