import { NgModule, Optional, SkipSelf } from "@angular/core";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { AuthModule } from "app/core/auth/auth.module";
import { IconsModule } from "app/core/icons/icons.module";
import { AlertModule } from "./alert/alert.module";

@NgModule({
  imports: [AuthModule, IconsModule, AlertModule, MatSnackBarModule],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error(
        "CoreModule has already been loaded. Import this module in the AppModule only."
      );
    }
  }
}
