import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { FuseFullscreenModule } from "@fuse/components/fullscreen";
import { FuseLoadingBarModule } from "@fuse/components/loading-bar";
import { FuseNavigationModule } from "@fuse/components/navigation";
import { MessagesModule } from "app/layout/common/messages/messages.module";
import { NotificationsModule } from "app/layout/common/notifications/notifications.module";
import { SearchModule } from "app/layout/common/search/search.module";
import { SharedModule } from "app/shared/shared.module";
import { ClassicLayoutComponent } from "./classic.component";

@NgModule({
  declarations: [ClassicLayoutComponent],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    MessagesModule,
    NotificationsModule,
    SearchModule,
    SharedModule,
  ],
  exports: [ClassicLayoutComponent],
})
export class ClassicLayoutModule {}
